EnocloudInventoryDefinitions['PurchaseQueryDto']

<template>
  <view-item title="采购付款明细">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <form-query :model="form.data" :method="table.get">
            <en-form-item label="付款搜索">
              <en-input v-model="form.data.quickSearch" placeholder="供应商/付款单号/采购单号"></en-input>
            </en-form-item>
          </form-query>
          <table-dynamic
            :data="table.data"
            :method="table.get"
            :paging="table.paging"
            :height="height - 55"
            pagination
            :loading="table.loading"
            code="PUCPAMFD"
            :config="table.config"
          >
            <template #PAYABLE_AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
              {{ formatMoney(row.amount) }}
            </template>
            <template #PAID_AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
              {{ formatMoney(row.paidAmount) }}
            </template>

            <template #SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
              <en-button type="primary" link @click="operation.open.click(row)">{{ row.serialNo }} </en-button>
            </template>
          </table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  >
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/purchase/payment/export',
                params(params) {
                  params.payload = this.table.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          },
          open: {
            async click(row: EnocloudSettlementDefinitions['PaymentDto']) {
              this.router.push('/accessory/stock/in/purchase', (vm) => {
                vm.form.data.id = row.id

                vm.form.get()
              })
            }
          }
        }
      },
      form: {
        is: 'form',
        data: {
          branchIds: [],
          quickSearch: ''
        },
        ajax: {
          submit: {
            action: 'GET /enocloud/purchase/payment'
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/payment',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },

        config: {
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          WAREHOUSE: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['documentStartDate', 'documentEndDate'],
                props: { type: 'daterange' }
              }
            }
          },

          PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'payment.paymentMethods',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['PAMTMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          PAID_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/summary/:type',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = this.table.$ajaxParams.paths
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
